import { Controller } from "@hotwired/stimulus";
import tippy from "tippy.js";

export default class extends Controller {
  static targets = ["input"];

  copy(e) {
    const input = this.inputTarget;
    const textToCopy = input.value || input.textContent;
    navigator.clipboard.writeText(textToCopy.trim());

    this.tooltip = tippy(e.target, {
      content: "Copied!",
      placement: "top",
      trigger: "manual",
    });

    this.tooltip.show();

    setTimeout(() => {
      this.tooltip.hide();
    }, 1000);
  }
}
