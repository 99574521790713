import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["option", "email", "other", "submitButton"];

  connect() {
    this.toggleIntroContent = this.toggleIntroContent.bind(this);
    let currentValue = "";

    this.optionTargets.forEach((option) => {
      const radio = option.querySelector("input[type='radio']");

      if (radio.checked) {
        currentValue = radio;
      }
    });

    this.toggleIntroContent({ target: currentValue });
  }

  toggleIntroContent(event) {
    this.resetOptionWrappers();

    const optionWrapper = event.target.closest("[data-components--intro-modal-target='option']");

    optionWrapper.classList.add("border-violet-600");

    if (event.target.value === "email") {
      this.emailTarget.classList.remove("hidden");
      this.otherTarget.classList.add("hidden");

      this.submitButtonTarget.textContent = "Send Intro";
    } else {
      this.emailTarget.classList.add("hidden");
      this.otherTarget.classList.remove("hidden");

      this.submitButtonTarget.textContent = "Mark as sent";
    }
  }

  resetOptionWrappers() {
    this.optionTargets.forEach((option) => {
      option.classList.remove("border-violet-600");
    });
  }
}
