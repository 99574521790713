import { Controller } from "@hotwired/stimulus";
import { useTransition } from "stimulus-use";

export default class extends Controller {
  static targets = [
    "menu",
    "chevronIcon",
    "container",
    "inputField",
    "swatch",
    "triggerButton",
    "labelEl",
    "avatar",
    "searchInput",
    "remoteOptions",
  ];

  static values = {
    direction: String,
    orientation: String,
    size: String,
    disabled: Boolean,
    error: Boolean,
    loading: Boolean,
    placeholder: String,
    value: String,
    onChangeUrl: String,
    swatchColored: Boolean,
    showAvatar: Boolean,
    remote: Boolean,
    remoteUrl: String,
  };

  connect() {
    useTransition(this, {
      element: this.menuTarget,
      enterActive: "transition ease-out duration-100",
      enterFrom: "transform opacity-0 -translate-y-2",
      enterTo: "transform opacity-100 translate-y-0",
      leaveActive: "transition ease-in duration-100",
      leaveFrom: "transform opacity-100 translate-y-0",
      leaveTo: "transform opacity-0 -translate-y-2",
    });

    if (this.hasTriggerButtonTarget) {
      this.triggerButtonTarget.setAttribute("aria-expanded", "false");
    }

    if (this.valueValue && this.hasInputFieldTarget) {
      this.inputFieldTarget.value = this.valueValue;
    }
  }

  searchOptions(event) {
    if (!this.remoteValue) return;

    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      const query = event.target.value.trim();
      if (query.length > 1) {
        this._fetchOptions(query);
      }
    }, 600);
  }

  handleKeydown(event) {
    if (event.key === "Escape") {
      this.leave();
    }
  }

  toggle(event) {
    event.preventDefault();
    const isHidden = this.menuTarget.classList.contains("hidden");

    if (isHidden) {
      this.enter();
      this._setAriaExpanded(true);
      this.chevronIconTarget.classList.add("-rotate-180");
    } else {
      this.leave();
      this._setAriaExpanded(false);
      this.chevronIconTarget.classList.remove("-rotate-180");
    }
  }

  hide(event) {
    if (
      !this.element.contains(event.target) &&
      !this.menuTarget.classList.contains("hidden")
    ) {
      this.leave();
      this._setAriaExpanded(false);
      this.chevronIconTarget.classList.remove("-rotate-180");
    }
  }

  selectOption(event) {
    const optionId = event.currentTarget.getAttribute(
      "data-select-option-id-value"
    );
    const optionName = event.currentTarget.getAttribute(
      "data-select-option-name-value"
    );
    const optionColor = event.currentTarget.getAttribute(
      "data-select-color-value"
    );
    const optionAvatarElement = event.currentTarget.querySelector(
      "[data-select-avatar-value]"
    );

    // 1. Update the label text (labelElTarget)
    if (this.hasLabelElTarget) {
      this.labelElTarget.classList.remove("text-neutral-200");
      this.labelElTarget.textContent = optionName;
    }

    // 2. Update hidden input
    if (this.hasInputFieldTarget) {
      this.inputFieldTarget.value = optionId;
    }

    // 3. Update container background color (if swatchColored)
    if (optionColor && this.swatchColoredValue) {
      this._updateBackgroundColor(optionColor);
    } else {
      this._updateBackgroundColor("white");
    }

    // 4. Update the color swatch if we have swatchTarget and we are NOT in swatchColored mode
    if (this.hasSwatchTarget && optionColor && !this.swatchColoredValue) {
      this._updateSwatchColor(optionColor);
    }

    // 5. Update the avatar if we have avatarTarget and we are in showAvatar mode
    if (this._shouldUpdateAvatar(optionAvatarElement)) {
      this._updateAvatar(optionAvatarElement);
    }

    // 6. Highlight the newly selected option in the dropdown
    this._highlightSelectedOption(event.currentTarget);

    // 7. Close the dropdown
    this.leave();
    this._setAriaExpanded(false);
    this.chevronIconTarget.classList.remove("-rotate-180");

    // 8. If there's an onChangeUrl, send a PATCH to that endpoint
    if (this.onChangeUrlValue) {
      this._postSelection(optionId);
    }
  }

  // -- Private Helpers --

  _setAriaExpanded(expanded) {
    if (this.hasTriggerButtonTarget) {
      this.triggerButtonTarget.setAttribute(
        "aria-expanded",
        expanded.toString()
      );
    }
  }

  _updateBackgroundColor(newColor) {
    if (this.hasContainerTarget) {
      this.containerTarget.style.backgroundColor = newColor;
    }
  }

  _updateSwatchColor(newColor) {
    // Show the color swatch, set its background color
    this.swatchTarget.classList.remove("hidden");
    this.swatchTarget.style.backgroundColor = newColor;
  }

  _shouldUpdateAvatar(avatarElement) {
    return this.hasAvatarTarget && this.showAvatarValue && avatarElement;
  }

  _updateAvatar(avatarElement) {
    if (!this.hasAvatarTarget) return;

    this.avatarTarget.classList.remove("hidden");
    this.avatarTarget.outerHTML = avatarElement.outerHTML;
  }

  _fetchOptions(query) {
    if (!query) {
      this.remoteOptionsTarget.innerHTML = "";
      return;
    }

    const url = new URL(this.remoteUrlValue, window.location.href);
    url.searchParams.set("query", query);

    fetch(url, {
      headers: { Accept: "text/html" },
    })
      .then((response) => response.text())
      .then((html) => {
        this.remoteOptionsTarget.innerHTML = html;
      })
      .catch((error) => console.error("Error fetching options:", error));
  }

  _highlightSelectedOption(selectedElement) {
    const allOptions = this.menuTarget.querySelectorAll(
      "button[data-select-option-id-value]"
    );
    allOptions.forEach((btn) => {
      btn.classList.remove("bg-primary-50");
    });
    selectedElement.classList.add("bg-primary-50");
  }

  _postSelection(optionId) {
    fetch(this.onChangeUrlValue, {
      method: "PATCH",
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        "Content-Type": "application/json",
        "X-CSRF-Token": this._getMetaValue("csrf-token"),
      },
      body: JSON.stringify({ user_group_id: optionId }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.text();
      })
      .then((html) => {
        if (html) {
          Turbo.renderStreamMessage(html);
        }
      })
      .catch((error) => {
        console.error("Update error:", error);
      });
  }

  _getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`);
    return element && element.getAttribute("content");
  }
}
